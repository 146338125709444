import prestashop from 'prestashop';
import Swiper from 'swiper';
import 'jquery-zoom/jquery.zoom.min';
jQuery.migrateMute = true;

window.$ = window.jQuery = require('jquery');
const fancybox = require('@fancyapps/fancybox');
$.fancybox.defaults.hash = false;
$.fancybox.defaults.loop = true;
$.fancybox.defaults.touch = false;

// GSap
import { gsap, Power1, Power2, Power3, Power4 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Iro js 
import iro from '@jaames/iro';


function updateViewportDimensions() {
    var w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0], x = w.innerWidth || e.clientWidth || g.clientWidth, y = w.innerHeight || e.clientHeight || g.clientHeight;
    return {width: x, height: y};
}

var viewport = updateViewportDimensions();


var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};


jQuery(document).ready(function ($) {

    // =========================================================================
    // UPLOAD FILES
    // =========================================================================
    jQuery('.upload-file').change(function () {
        var file = jQuery(this).find('input')[0].files[0].name;
        jQuery(this).find('.upload-file-name').text(file);
    });


    // =========================================================================
    // Product Filter mobile
    // =========================================================================
    jQuery('.bt-categories-filter-trigger').click(function () {
        if (jQuery('body').hasClass('bt-categories-filter-open')) {
            jQuery('body').removeClass('bt-categories-filter-open');
        } else {
            jQuery('body').addClass('bt-categories-filter-open');
        }
        return false;
    });
    jQuery('.bt-categories-filter-overlay').click(function () {
        jQuery('body').removeClass('bt-categories-filter-open');
        return false;
    });


    // =========================================================================
    // Product Facets
    // =========================================================================
    listingFacetFilters();

    prestashop.on('updateProductList', function (event) {
        listingFacetFilters();

        jQuery("html, body").animate({
            scrollTop: $('#js-product-list-top').offset().top - 15
        }, 350);

    });


    // =========================================================================
    // Product Cover Zoom
    // =========================================================================
    jQuery('.activate-zoom').each(function () {
        var data_url = jQuery(this).attr('data-url');
        jQuery(this).addClass('zoom-in').zoom({
            url: data_url
        });
    });
    jQuery('.product-single .product-swiper .trigger-cover').click(function () {
        ProductZoom_restart();
        return false;
    });


    // =========================================================================
    // Product Cover Quickview Zoom
    // =========================================================================
    jQuery('.product-cta-action.quick-view').on('click', function () {
        setTimeout(function () {
            ProductZoom_restart();
        }, 500);
    });


    // =========================================================================
    // Product Cover Swiper
    // =========================================================================
    productSwiper();
    prestashop.on('updatedProduct', function (event) {
        productSwiper();
        ProductZoom_restart();
    });

    // =========================================================================
    // BC Tabs
    // =========================================================================
    jQuery('.BC_tabs .tab-trigger').click(function () {
        var tab_trigger = jQuery(this).attr('data-target');
        if (jQuery(this).closest('li').hasClass('active')) {

        } else {
            jQuery(this).closest('.BC_tabs').find('.active').removeClass('active');
            jQuery(this).closest('li').addClass('active');
            jQuery(this).closest('.BC_tabs').find('#' + tab_trigger).addClass('active');
        }
    });

    // =========================================================================
    // Product page quantity min required
    // =========================================================================
    jQuery('body#product #quantity_wanted').focusout(function () {
        var input = jQuery(this)[0];
        if (input.value >= input.min) {
            // OK 
        } else {
            // Replace with min
            jQuery(this).val(input.min);
        }
    });
});

// =============================================================================
// Product Facets
// =============================================================================
gsap.set(jQuery('.facet .facet-list li'), {
    y: -10,
    opacity: 0,
});

function listingFacetFilters() {

    if (jQuery('body').find('#search_filters').length) {
        jQuery('.facet').each(function () {
            if (jQuery(this).find('.facet-label.active').length) {
                jQuery(this).closest('.facet').find('.facet-title').removeClass('collapsed');
                jQuery(this).closest('.facet').find('.facet-title.text').append('<span class="num">(' + jQuery(this).find('.facet-label.active').length + ')</span>');
                jQuery(this).closest('.facet').find('.facet-list').addClass('collapse show');
            }
        });
    }
}
jQuery(document).on('click', '.facet-title.trigger', function () {
    if (jQuery(this).closest('.facet').hasClass('show-facet')) {
        jQuery(this).closest('.facet').removeClass('show-facet');
    } else {
        jQuery('#search_filters .show-facet').removeClass('show-facet');
        jQuery(this).closest('.facet').addClass('show-facet');
    }
});

jQuery(document).on('click', '.facet .facet-title.trigger', function () {
    jQuery(this).parents('.facet').toggleClass('open');

    if (jQuery(this).parents('.facet').hasClass('open')) {
        gsap.to(jQuery(this).parents('.facet').find('.facet-list'), {
            maxHeight: 320,
            duration: .7,
            ease: Power2.easeOut,
        });
        gsap.to(jQuery(this).parents('.facet').find('.facet-list li'), {
            y: 0,
            opacity: 1,
            stagger: .1,
            ease: Power2.easeOut,
        });
    } else {
        gsap.to(jQuery(this).parents('.facet').find('.facet-list'), {
            maxHeight: 0,
            duration: .7,
            ease: Power2.easeOut,
        });
        gsap.to(jQuery(this).parents('.facet').find('.facet-list li'), {
            y: -10,
            opacity: 0,
            stagger: .1,
            ease: Power2.easeOut,
        });
    }
});

// =============================================================================
// Product Swiper
// =============================================================================
function productSwiper() {

    jQuery('.product-single .product-swiper').each(function () {
        var productSwiper = new Swiper(jQuery(this).find('.swiper-container'), {
            slidesPerView: 5,
            watchOverflow: true,
            simulateTouch: false,
            navigation: {
                prevEl: jQuery(this).find('.swiper-product-prev'),
                nextEl: jQuery(this).find('.swiper-product-next')
            }
        });
    });
}

function ProductZoom_restart() {
    jQuery('body').find('.activate-zoom').each(function () {
        var data_cover = jQuery(this).attr('data-cover');

        jQuery(this).removeClass('zoom-in').trigger('zoom.destroy');
        jQuery(this).attr('data-url', data_cover);
        jQuery(this).addClass('zoom-in').zoom({url: data_cover});
    });
}

// =============================================================================
// Alert
// =============================================================================
jQuery(document).on('click', '.alert', function (e) {
    if (e.target !== this) {
        return;
    } else {
        jQuery('.alert').hide(250);

        setTimeout(function () {
            jQuery('.alert').remove();
        }, 350);
    }
});

// =============================================================================
// Configurateur
// =============================================================================
jQuery(document).on('click', '.dp_cart.dp_seven_cart', function (e) {
    if (jQuery(this).hasClass('active')) {
        jQuery(this).removeClass('active');
    } else {
        jQuery(this).addClass('active');
    }
});

// =============================================================================
// Notes client
// =============================================================================
jQuery(document).on('click', '.fancybox.ajax', function (e) {
    jQuery(this).fancybox({
        type: 'ajax'
    });
});

// =============================================================================
// Steps for checkout
// =============================================================================
jQuery(document).on('click', '#checkout a[data-trigger-step]', function () {
    var triggerStep = jQuery(this).attr('data-trigger-step');
    var targetElement = jQuery('[data-target-step="' + triggerStep + '"]');

    if (targetElement.closest('.tunnel-step').hasClass('-clickable')) {
        if (targetElement.length > 0) {
            targetElement.trigger('click');
            // Fallback to native click
            if (typeof targetElement[0].click === 'function') {
                targetElement[0].click();
            } else {
            }
        } else {
        }
    } else {
        // Element is not clickable
    }
    
    updateStepNavigation();
});

jQuery(document).on('click', '[data-trigger-tab]', function () {
    var triggerTab = jQuery(this).attr('data-trigger-tab');
    jQuery(this).closest('.tabs').find('.nav-link[data-link-action="' + triggerTab + '"]').trigger('click');
});

jQuery(document).on('click', '.tabs .nav-link', function () {
    var triggerTab = jQuery(this).attr('href');
    jQuery(this).closest('.tabs').find('.nav-link').removeClass('active');
    jQuery(this).closest('.tabs').find('.tab-pane').removeClass('active');
    
    jQuery(this).closest('.tabs').find('.nav-link[href="' + triggerTab + '"]').addClass('active');
    jQuery(this).closest('.tabs').find(triggerTab).addClass('active');
});

jQuery(document).ready(function () {
    updateStepNavigation();
});

function updateStepNavigation() {

    jQuery('.tunnel-step').each(function (index) {
        if (jQuery(this).hasClass('-complete')) {
            jQuery('.cardbox-checkout-navigation .step-' + (index + 1)).addClass('done');
        }
        if (jQuery(this).hasClass('-current')) {
            jQuery('.cardbox-checkout-navigation .step-' + (index + 1)).addClass('active');
        }
    });
}

// =============================================================================
// Wishlist
// =============================================================================
jQuery(document).on('click', '.nxtal-wishlist-selector.dropdown .dropdown-item', function () {

    setTimeout(function () {

        jQuery('.nxtal-wishlist-selector').each(function () {
            if (jQuery(this).find('.dropdown-item.added-to-list').length) {
                // Found 
                jQuery(this).find('.nxtal-wishlist-dropdowntrigger .licon-wish').closest('button').addClass('active');
                jQuery(this).find('.nxtal-wishlist-dropdowntrigger .licon-wish').addClass('active');
            } else {
                // No likes
                jQuery(this).find('.nxtal-wishlist-dropdowntrigger .licon-wish').closest('button').removeClass('active');
                jQuery(this).find('.nxtal-wishlist-dropdowntrigger .licon-wish').removeClass('active');
            }
        });
    }, 750);
});

jQuery(document).on('click', '#growls .growl, #growls .growl-close', function (e) {
    e.preventDefault();
    jQuery('#growls .growl').fadeOut(250);

    setTimeout(function () {
        jQuery('#growls .growl').remove();
    }, 250);
});

jQuery(document).on('click', '[do-action="#commentaires-clients"]', function(){
    
    jQuery('html,body').animate({
        scrollTop: jQuery('#commentaires-clients').offset().top - 150
    }, 1000);
});

// =============================================================================
// Iro
// =============================================================================
/*
 <div class="ColorPicker" id="sliderPicker">
 <h3>"It's all sliders!"</h3>
 </div>
 */
if (jQuery('#sliderPicker').length) {

    var sliderPicker = new iro.ColorPicker("#sliderPicker", {
        width: 246,
        sliderSize: 13,
        color: "rgb(255, 0, 0)",
        saturation: 30,
        value: 90,
        borderWidth: 0,
        borderColor: "#fff",
        layout: [
            {
                component: iro.ui.Slider,
                options: {
                    sliderType: 'hue'
                }
            },
            {
                component: iro.ui.Slider,
                options: {
                    sliderType: 'saturation'
                }
            },
            {
                component: iro.ui.Slider,
                options: {
                    sliderType: 'value'
                }
            },
                    /*{
                     component: iro.ui.Slider,
                     options: {
                     sliderType: 'alpha'
                     }
                     },*/
        ]
    });

    sliderPicker.on('color:change', function (color) {
        console.log(color.hexString);

        jQuery('[data-color-react]').css({
            'background-color': color.hexString
        });
    });
}

// Version 2024-11-05
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// GSap
import { gsap, Power1, Power2, Power3, Power4 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// =============================================================================
// Bloc animation
// =============================================================================
jQuery(document).ready(function ($) {
    handleBloc();
    handleAnimation();

    $(window).on('scroll resize', handleBloc);
    $(window).on('scroll resize', handleAnimation);
    $(window).trigger('resize');
});

function handleBloc() {
    const containers = document.querySelectorAll('.bloc, .bloc-cta');

    // Default background type
    let backgroundFound = false;
    let bgType = 'light';

    containers.forEach(container => {
        const bloc = $(container);
        const blocTop = bloc.offset().top;
        const limiterClass = bloc.find('.limiter-max, .limiter').attr('class');
        const enteredBlocClass = bloc.attr('class');

        if (blocTop <= window.scrollY && (blocTop + bloc.height()) > window.scrollY) {
            if (limiterClass && limiterClass.includes('bg-')) {
                bgType = limiterClass.includes('bg-gris') || limiterClass.includes('bg-noir') || limiterClass.includes('bg-main') || limiterClass.includes('bg-type') ? 'dark' : 'light';
            } else if (enteredBlocClass && enteredBlocClass.includes('bg-')) {
                bgType = enteredBlocClass.includes('bg-gris') || enteredBlocClass.includes('bg-noir') || enteredBlocClass.includes('bg-main') || enteredBlocClass.includes('bg-type') ? 'dark' : 'light';
            } else {
                bgType = 'light';
            }
        }
    });
    $('body').attr('data-bg', bgType);
}

function handleAnimation() {

    jQuery('.has-animate, .bloc, .bloc-cta').each(function () {
        var animatedElem = jQuery(this).offset().top;
        var windowHeight = $(window).height();
        var scroll = $(window).scrollTop();

        if (scroll + windowHeight > animatedElem) {
            jQuery(this).addClass('animate');
        } else {
            jQuery(this).removeClass('animate');
        }
    });
}


// =============================================================================
// GSap Functions
// =============================================================================
(function ($) {

    $.fn.gsap_reveal = function () {
        return this.each(function () {
            var result = get_classesToKeyValue(jQuery(this), '-gsap-');

            gsap.fromTo(jQuery(this), {
                x: result['-gsap-x'] || 0,
                y: result['-gsap-y'] || 0,
                opacity: result['-gsap-o'] || 1,
            }, {
                duration: result['-gsap-dur'] || '.7',
                delay: result['-gsap-delay'] || '0',
                x: 0,
                y: 0,
                opacity: 1,
                stagger: result['-gsap-stagger'] || '0',
                ease: result['-gsap-ease'] || Power2.easeOut,
                scrollTrigger: {
                    trigger: jQuery(this).closest('[gsap-wrapper]'),
                    start: result['-gsap-start'] || '0 65%',
                    end: result['-gsap-end'],
                    scrub: result['-gsap-scrub'],
                    toggleActions: result['-gsap-actions'] || "play none none none",
                }
            });
        });
    };

    $.fn.gsap_parallax = function () {
        return this.each(function () {
            var gsap_parallax = gsap.timeline({
                scrollTrigger: {
                    trigger: jQuery(this).closest('[gsap-wrapper]'),
                    start: '0% 50%',
                    end: '100% 50%',
                    scrub: true,
                    invalidateOnRefresh: true,
                }
            });

            gsap_parallax.fromTo(jQuery(this), {
                y: '-20%',
                ease: 'none'
            }, {
                y: '0%',
                ease: 'none'
            });
        });
    };

    $.fn.gsap_parallax_scroll = function () {
        return this.each(function () {

            if (jQuery(this).attr('gsap-react-scroll') === '') {
                var effect = ['-50', '50'];
            } else {
                var effect = jQuery(this).attr('gsap-react-scroll').split(',');
            }

            gsap.fromTo(jQuery(this), {
                y: effect[0],
            }, {
                y: effect[1],
                ease: "none",
                scrollTrigger: {
                    trigger: jQuery(this).parents('.bloc'),
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true,
                    invalidateOnRefresh: true, // to make it responsive
                }
            });
        });
    };

    $.fn.gsap_numbers = function () {
        return this.each(function () {
            if (jQuery(this).html() !== '0') {
                jQuery(this).attr('data-num', jQuery(this).html());

                var gsap_number = gsap.timeline({
                    scrollTrigger: {
                        trigger: jQuery(this).closest('[gsap-wrapper]'),
                        start: '0 70%',
                        toggleActions: "play none none none",
                    }
                });

                gsap_number.from(jQuery(this), {
                    textContent: 0,
                    duration: 1.5,
                    ease: Power2.easeIn,
                    snap: {textContent: 1},
                    stagger: 1,
                });
            }
        });
    };

    $.fn.gsap_scrollFixedContent = function () {
        return this.each(function () {
            var $sticky_elem = jQuery(this);
            var $sticky_container = jQuery(this).parents('.sticky-container');
            var breakpoint = ($sticky_elem.attr('scrollTrigger-breakpoint')) ? $sticky_elem.attr('scrollTrigger-breakpoint') : 1025;

            if (window.innerWidth >= breakpoint) {
                var data_scrollTrigger_start = ($sticky_elem.attr('scrollTrigger-start')) ? $sticky_elem.attr('scrollTrigger-start') : 'top 0px';
                var data_scrollTrigger_end = ($sticky_elem.attr('scrollTrigger-end')) ? $sticky_elem.attr('scrollTrigger-end') : 'bottom ' + $sticky_elem.find('.sticky-content-height').height() + 'px';

                gsap.to($sticky_container, {
                    scrollTrigger: {
                        trigger: $sticky_elem,
                        pin: true,
                        start: data_scrollTrigger_start,
                        end: data_scrollTrigger_end,
                        endTrigger: $sticky_container,
                        invalidateOnRefresh: true,
                        onToggle: function (self) {
                            $sticky_elem.attr('is-sticky', self.isActive);
                        },
                    },
                    ease: "none"
                });
            }

            // Use ResizeObserver to monitor width/height changes
            const resizeObserver = new ResizeObserver(() => {
                ScrollTrigger.refresh();  // Trigger refresh on resize
            });
            resizeObserver.observe($sticky_container[0]);
        });
    };

    $.fn.gsap_scrollSections = function () {
        return this.each(function () {
            var $scrollSections_wrapper = jQuery(this);
            var breakpoint = ($scrollSections_wrapper.attr('scrollsections-breakpoint')) ? $scrollSections_wrapper.attr('scrollsections-breakpoint') : 1025;

            if (window.innerWidth >= breakpoint) {
                var $sections = $scrollSections_wrapper.find('.scrollcontent-section');
                var $imagesWrapper = $scrollSections_wrapper.find('.scrollcontent-images');

                // Pin the image wrapper so it stays visible on the right side
                gsap.timeline({
                    scrollTrigger: {
                        trigger: $scrollSections_wrapper,
                        start: "top top",
                        end: "bottom bottom",
                        pin: $imagesWrapper, // Pin the images wrapper
                        pinSpacing: false,
                        onToggle: function (self) {
                            $scrollSections_wrapper.attr('is-sticky', self.isActive);
                        },
                    }
                });

                // Loop through each section to create the ScrollTrigger
                $sections.each(function (index, section) {
                    var $section = jQuery(this);
                    var sectionId = $section.attr('data-id');

                    // Create ScrollTrigger for each section
                    gsap.timeline({
                        scrollTrigger: {
                            trigger: $section,
                            start: "top 50%", // Start when the section hits the middle of the viewport
                            end: "bottom 50%",
                            scrub: true,
                            onEnter: function () {
                                $scrollSections_wrapper.attr('data-current', sectionId);
                                $scrollSections_wrapper.find('.scrollcontent-images .active').removeClass('active');
                                $scrollSections_wrapper.find('.scrollcontent-images [data-id="' + sectionId + '"]').addClass('active');
                            },
                            onEnterBack: function () {
                                $scrollSections_wrapper.attr('data-current', sectionId);
                                $scrollSections_wrapper.find('.scrollcontent-images .active').removeClass('active');
                                $scrollSections_wrapper.find('.scrollcontent-images [data-id="' + sectionId + '"]').addClass('active');
                            },
                        }
                    });
                });
            }
        });
    };

    $.fn.gsap_drawLines = function () {
        return this.each(function () {

            gsap.timeline({
                scrollTrigger: {
                    trigger: jQuery(this),
                    start: "top 80%",
                    end: "bottom top",
                    toggleActions: "play none none reverse",
                }
            }).to(jQuery(this).find('svg path'), {
                duration: 1,
                drawSVG: '100%',
                stagger: 0.2,
            });
        });
    };
})(jQuery);

function get_classesToKeyValue(selector, findKeys) {
    var gsapClasses = {};
    var classes = selector.attr('class').split(' ');

    classes.forEach(function (className) {
        if (className.startsWith(findKeys)) {
            // Extract key and value from the class name
            var parts = className.split('-').slice(2); // Remove '.-gsap-' and split by hyphen
            var key = findKeys + parts.slice(0, -1).join('-'); // Join parts except the last one
            var value = parts.slice(-1)[0]; // Take the last part

            gsapClasses[key] = value;
        }
    });
    return gsapClasses;
}

jQuery(document).ready(function () {
    jQuery('[gsap], .-gsap').gsap_reveal();

    if (jQuery('.-gsapnumbers').length) {
        jQuery('.-gsapnumbers').gsap_numbers();
    }

    if (jQuery('.gsap-drawlines').length) {
        jQuery('.gsap-drawlines').gsap_drawLines();
    }

    if (jQuery('.embed-responsive-parallax').length) {
        jQuery('.embed-responsive-parallax .js-background').gsap_parallax();
    }

    if (jQuery('[gsap-react-scroll]').length) {
        jQuery('[gsap-react-scroll]').gsap_parallax_scroll();
    }

    if (jQuery('body').find('.sticky-content').length) {
        jQuery('.sticky-content').gsap_scrollFixedContent();
    }
    if (jQuery('body').find('.scrollcontent-wrapper').length) {
        jQuery('.scrollcontent-wrapper').gsap_scrollSections();
    }
});

jQuery(window).on('resize', function () {
    jQuery('[gsap], .-gsap').gsap_reveal();

    if (jQuery('.embed-responsive-parallax').length) {
        jQuery('.embed-responsive-parallax .js-background').gsap_parallax();
    }

    if (jQuery('[gsap-react-scroll]').length) {
        jQuery('[gsap-react-scroll]').gsap_parallax_scroll();
    }
});

// =============================================================================
// GSAP Mouse react
// =============================================================================
jQuery(document).mousemove(function (event) {
    jQuery(this).find('[gsap-mouse-react]').each(function () {
        var shiftValue = jQuery(this).attr('data-shift'),
                moveX = (event.clientX * shiftValue) / 250,
                moveY = (event.clientY * shiftValue) / 250;

        gsap.to(jQuery(this), {
            x: moveX,
            y: moveY,
            duration: 1
        });
    });
});
jQuery(document).on('mousemove', '[gsap-mouse-react-container]', function (event) {
    jQuery(this).find('[gsap-mouse-react-contained]').each(function () {
        var shiftValue = jQuery(this).attr('data-shift'),
                moveX = (event.pageX * shiftValue) / 250,
                moveY = (event.pageY * shiftValue) / 250;

        gsap.to(jQuery(this), {
            x: moveX,
            y: moveY,
            // duration: 1 
        });
    });
});

// =============================================================================
// GSAP Sticky Visuel
// =============================================================================
jQuery(document).ready(function ($) {
    gsap_stickyVisuel_setup();
    gsap_stickyVisuel();

    $(window).on('scroll resize', gsap_stickyVisuel);
    $(window).trigger('resize');
});

function gsap_stickyVisuel_setup() {
    jQuery('.sticky-visuel-container').each(function () {
        var $sticky_container = jQuery(this);
        var sticky_visuel = null;
        jQuery(this).find('.sticky-visuel-trigger').each(function () {
            if (jQuery(this).attr('data-visuel') !== '') {
                sticky_visuel = jQuery(this).attr('data-visuel');
                $sticky_container.find('.sticky-visuel').removeClass('o-0');
            } else {
                $sticky_container.find('.sticky-visuel').addClass('o-0');
            }
            return false;
        });

        if (sticky_visuel) {
            $sticky_container.find('.sticky-visuel .js-background').css('background-image', 'url(' + sticky_visuel + ')');
        }
    });
}

function gsap_stickyVisuel() {
    jQuery('.sticky-visuel-container .sticky-visuel-trigger').each(function () {
        var animatedElem = jQuery(this).offset().top;
        var windowHeight = $(window).height();
        var scroll = $(window).scrollTop();

        var sticky_visuel = jQuery(this).attr('data-visuel');
        var $sticky_container = jQuery(this).closest('.sticky-visuel-container');


        if (scroll + (windowHeight / 2) > animatedElem) {
            if (jQuery(this).attr('data-visuel') !== '') {
                // jQuery(this).addClass('animate');
                $sticky_container.find('.sticky-visuel').removeClass('o-0');
                if (sticky_visuel) {
                    $sticky_container.find('.sticky-visuel .js-background').css('background-image', 'url(' + sticky_visuel + ')');
                }
            } else {
                $sticky_container.find('.sticky-visuel').addClass('o-0');
            }
        } else {
            // jQuery(this).removeClass('animate');
        }
    });
}

jQuery(document).ready(function(){
   
    if(jQuery('body').find('.gformbuilderpro_form_2').length){
        var title = '<h2 class="BC_title mt-0 mb-30 cf">Vous souhaitez réaliser une lampe sur-mesure ? <br class="d-block" />Contactez-nous</h2>';
        jQuery('.gformbuilderpro_form_2 .formbuilder_group:first-child > .formbuilder_column:first-child > div').before(title);
    }
    
    if(jQuery('body').find('.gformbuilderpro_form_3').length){
        var title = '<h2 class="BC_title mt-0 mb-30 cf">Remplissez le formulaire ci-dessous</h2>';
        jQuery('.gformbuilderpro_form_3 .formbuilder_group:first-child > .formbuilder_column:first-child > div').before(title);
    }
});

jQuery(document).on('click', '[trigger-action]', function () {
    var doAction = jQuery(this).attr('trigger-action');

    if (doAction) {
        if (jQuery('body').attr('trigger-action') === doAction) {
            // Already done, then remove
            jQuery('body').attr('do-action', '');
        } else {
            // Perform
            jQuery('body').attr('do-action', 'action-' + doAction);
        }
    }
});
jQuery(document).on('click', '[trigger-action-close]', function () {
    jQuery('body').attr('do-action', '');
});

jQuery(document).on('click', '[click-class]', function () {
    var target = jQuery(this).attr('click-class').split('|')[0];
    var classList = jQuery(this).attr('click-class').split('|')[1].replace(/\[|\]/g, '').split(',');

    if (target === 'this') {
        jQuery(this).toggleClass(classList.join(' '));
    } else if (target.includes('this::')) {
        jQuery(this).find(target.replace('this::', '')).toggleClass(classList.join(' '));
    } else if (target === '::parent') {
        jQuery(this).closest(target).toggleClass(classList.join(' '));
    } else {
        jQuery(target).toggleClass(classList.join(' '));
    }
});

jQuery(document).on('click', '[do_action]', function () {
    var action = jQuery(this).attr('do_action');

    if (action === 'scrollto-product-tabs') {
        jQuery("html, body").animate({
            scrollTop: $('#product-tabs').offset().top - 60,
        }, 500);
    }
});

jQuery(document).on('click', '.js-readmore-container .js-readmore', function () {
    var data_open = jQuery(this).closest('.js-readmore-container').attr('data-open') || 'Lire la suite';
    var data_close = jQuery(this).closest('.js-readmore-container').attr('data-close') || 'Fermer';

    if (jQuery(this).closest('.js-readmore-container').hasClass('active')) {
        jQuery(this).find('.text').html(data_open);
    } else {
        jQuery(this).find('.text').html(data_close);
    }
    jQuery(this).closest('.js-readmore-container').toggleClass('active');
});

$(document).ready(function() {
    // Toggle dropdown menu on click of data-toggle="dropdown"
    $('.js-dropdown').on('click', '[data-toggle="dropdown"]', function(e) {
        e.preventDefault();
        const $this = $(this);
        const $parent = $this.closest('.js-dropdown');
        const $menu = $parent.find('.dropdown-menu');

        $parent.toggleClass('show active');
        // $menu.stop(true, true).slideDown();
    });

    // Close dropdown on click outside
    $(document).on('click', function(e) {
        if (!$(e.target).closest('.js-dropdown').length) {
            $('.js-dropdown').removeClass('show active');
            // $('.js-dropdown').find('.dropdown-menu').stop(true, true).slideUp();
        }
    });
});

jQuery(document).on('click', '.collapse-button', function () {
    var target_ID = jQuery(this).attr('href');
    jQuery(target_ID).toggleClass('show in');
    /*jQuery('html,body').animate({
        scrollTop: jQuery(target_ID).offset().top - 60
    }, 1000);*/
});